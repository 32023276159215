<template>
  <div @click="resetBackToHall" @scroll="resetBackToHall" class="custom-height-100p product-box">
    <div class="tab-btn-box">
      <div
        :class="activeKey === 'sub_products' ? `product-btn product-btn-active` : 'product-btn'"
        @click="changeTab('sub_products')"
      >
        订购产品
      </div>
      <div
        :class="activeKey === 'already_sub' ? `product-btn product-btn-active` : 'product-btn'"
        @click="changeTab('already_sub')"
      >
        已购产品
      </div>
    </div>
    <router-view v-if="activeKey === this.$route.name" @resetBackToHall="resetBackToHall" />
  </div>
</template>

<script>
export default {
  name: 'ProductTab',
  data() {
    return {
      activeKey: 'sub_products',
      loopTimeout: 300000,
      backToHallTimer: null
    }
  },
  computed: {
    hallId() {
      return parseInt(this.$route.query.hall_id)
    },

    serviceOrderId() {
      return parseInt(this.$route.query.service_order_id)
    }
  },
  created() {
    this.activeKey = this.$route.name
    this.loopBackToHall()
  },
  destroyed() {
    clearTimeout(this.backToHallTimer)
  },
  methods: {
    loopBackToHall() {
      this.backToHallTimer = setTimeout(() => {
        this.$router.push({ name: 'hall_info', params: { id: this.hallId }})
      }, this.loopTimeout)
    },

    resetBackToHall() {
      clearTimeout(this.backToHallTimer)
      this.loopBackToHall()
    },

    changeTab(key) {
      this.activeKey = key
      this.$router.push({ name: key, query: { hall_id: this.hallId, service_order_id: this.serviceOrderId }})
    }
  }
}
</script>
<style lang="less" scoped>
.product-box {
  display: flex;
  flex-direction: column;
}

.tab-btn-box {
  display: flex;
  margin-bottom: 20px;

  .product-btn {
    padding: 10px;
    width: 50%;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #2a4c39;
  }

  .product-btn-active {
    color: #333;
    background-color: #ffd794ff;
  }
}
</style>

